import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { isPlatformServer } from '@angular/common';

const { plpApiBaseUrl, liveBaseUrl } = environment;

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private router: Router
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { method, url } = request;

    if (method === 'GET' && url.startsWith(plpApiBaseUrl)) {
      const modifiedRequest = request.clone({
        setParams: isPlatformServer(this.platformId)
          ? {
              url: liveBaseUrl + this.router.url,
              ref_url: ''
            }
          : {
              url: window.location.href,
              ref_url: ''
            }
      });

      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}
