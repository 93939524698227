import { createReducer, on } from '@ngrx/store';
import { addProductToCatalog, removeProductFromCatalog, clearAllProductFromCatalog } from './catalogList.actions';
import { ProductResponseDocsModel } from '@models/product/productResponseDocs.model';

export interface IProductState {
  catalogList: ProductResponseDocsModel[];
}

export const initialState: IProductState = {
  catalogList: []
};
export const catalogReducer = createReducer(
  initialState,
  on(addProductToCatalog, (state, { product }) => ({
    ...state,
    catalogList: [...state.catalogList, product]
  })),
  on(removeProductFromCatalog, (state, { productId }) => ({
    ...state,
    catalogList: state.catalogList.filter((p: ProductResponseDocsModel) => p.pid !== productId)
  })),
  on(clearAllProductFromCatalog, () => initialState)
);
