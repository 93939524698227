import { ProductResponseDocsModel } from '@app/core/models/product/productResponseDocs.model';
import { createReducer, on } from '@ngrx/store';
import { addSelectedPlpProduct, removeAllSelectedPlpProducts, removeSelectedProductsById } from './plpList.action';

export interface IPlpProducts {
  productList: ProductResponseDocsModel[];
}

export const initialState: IPlpProducts = {
  productList: []
};

export const plpReducer = createReducer(
  initialState,
  on(addSelectedPlpProduct, (state, { products }) => ({
    ...state,
    productList: [...state.productList, products]
  })),
  on(removeSelectedProductsById, (state, { productId }) => ({
    ...state,
    productList: state.productList.filter((product) => product.pid !== productId)
  })),

  on(removeAllSelectedPlpProducts, (state) => ({
    ...state,
    productList: []
  }))
);
