import { createReducer, on } from '@ngrx/store';
import { addToCart, clearCart, decreaseSampleQuantity, increaseSampleQuantity, removeFromCart } from './cart.actions';
import { ProductDetailsResponse } from '@app/core/models/product/productDetailsResponse.model';

export interface ICartItem extends Partial<ProductDetailsResponse> {
  quantity: number;
}

export interface ICartState {
  cartItems: ICartItem[];
}

export const initialState: ICartState = {
  cartItems: []
};

export const cartReducer = createReducer(
  initialState,
  on(addToCart, (state, { product }) => {
    const existingItem = state.cartItems.find((item) => item.colorNumber === product?.colorNumber);
    if (existingItem) {
      return {
        ...state,
        cartItems: state.cartItems.map((item) =>
          item.colorNumber === product?.colorNumber
            ? {
                ...item,
                quantity: item.quantity + 1
              }
            : item
        )
      };
    } else {
      return {
        ...state,
        cartItems: [
          ...state.cartItems,
          {
            ...product,
            quantity: 1
          }
        ]
      };
    }
  }),
  on(removeFromCart, (state, { productColorNumber }) => ({
    ...state,
    cartItems: state.cartItems.filter((p: ICartItem) => p.colorNumber !== productColorNumber)
  })),
  on(increaseSampleQuantity, (state, { productColorNumber }) => ({
    ...state,
    cartItems: state.cartItems.map((p: ICartItem) =>
      p.colorNumber === productColorNumber ? { ...p, quantity: p.quantity + 1 } : p
    )
  })),
  on(decreaseSampleQuantity, (state, { productColorNumber }) => ({
    ...state,
    cartItems: state.cartItems.map((p: ICartItem) =>
      p.colorNumber === productColorNumber ? { ...p, quantity: p.quantity > 1 ? p.quantity - 1 : 1 } : p
    )
  })),
  on(clearCart, (state) => ({
    ...state,
    cartItems: []
  }))
);
