import { createReducer, on } from '@ngrx/store';
import {
  addProductToCompare,
  removeAllProductsFromCompare,
  removeProductFromCompare,
  setProductsToCompare
} from './cpp.actions';

export interface IProductStyleState {
  productStyleToCompare: string[];
}

export const initialState: IProductStyleState = {
  productStyleToCompare: []
};

export const productReducer = createReducer(
  initialState,
  on(setProductsToCompare, (state, { styleNumber }) => ({
    ...state,
    productStyleToCompare: styleNumber
  })),
  on(addProductToCompare, (state, { styleNumber }) => {
    if (styleNumber && !state.productStyleToCompare.find((p) => p === styleNumber)) {
      return {
        ...state,
        productStyleToCompare: [...state.productStyleToCompare, styleNumber]
      };
    }
    return state;
  }),
  on(removeProductFromCompare, (state, { styleNumber }) => ({
    ...state,
    productStyleToCompare: state.productStyleToCompare.filter((product) => product !== styleNumber)
  })),
  on(removeAllProductsFromCompare, (state) => ({
    ...state,
    productStyleToCompare: []
  }))
);
