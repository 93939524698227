import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import { TransferState, makeStateKey, StateKey } from '@angular/platform-browser';

@Injectable()
export class TransferStateInterceptor implements HttpInterceptor {
  constructor(private transferState: TransferState) {}

  private createTransferStateKey({ urlWithParams }: HttpRequest<unknown>): StateKey<string> {
    return makeStateKey<string>(`transferStateKey:${urlWithParams}`);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.method !== 'GET') {
      return next.handle(request);
    }

    const transferStateKey = this.createTransferStateKey(request);

    const cachedResponse = this.transferState.get<unknown>(transferStateKey, null);
    if (cachedResponse) {
      return of(new HttpResponse({ body: cachedResponse, status: 200 }));
    }

    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          this.transferState.set(transferStateKey, event.body);
        }
      }),
      shareReplay(1)
    );
  }
}
