<div class="notification-container">
  @for (notification of notifications; track notification) {
    @if (notification.type) {
      @switch (notification.type) {
        @case (notificationType.CART) {
          @if (getCartData(notification.data); as cardData) {
            <div class="cart-notification" [class.closing]="isClosing(notification.id)">
              <div class="cart-notification__left">
                <img class="cart-notification__left-swatchImage" [src]="cardData?.firstSwatchImage | safeUrl" />
              </div>
              <div class="cart-notification__right">
                <h3>{{ cardData.styleName }}</h3>
                <p>
                  {{ cardData.colorName }} {{ constants.DETAILS_PREFIX }}
                  {{ cardData.styleNumber }}
                </p>

                <div class="cart-notification__right-successText">
                  <img [src]="constants.SUCCESS_ICON" alt="" />
                  <p>{{ constants.ITEM_ADDED_TEXT }}</p>
                </div>
                <span>{{ constants.CART_TEXT }}</span>
              </div>
              <img
                class="cart-notification__closeIcon"
                [src]="constants.CART_CLOSE_ICON"
                alt=""
                (click)="remove(notification.id)"
              />
            </div>
          }
        }
        @default {
          <div
            class="notification"
            [ngClass]="getNotificationBgClass(notification.type)"
            [class.closing]="isClosing(notification.id)"
          >
            <div [ngClass]="getSidebarClass(notification.type)" class="notification__sidebar"></div>
            <span class="icon" [ngClass]="getIcon(notification.type)"></span>
            <div class="notification__content">
              <div class="notification__content-left">
                <p class="notification__content-left__message">{{ notification.message }}</p>
              </div>
            </div>
            <div class="notification__content-right">
              <img
                src="../../../../assets/icons/svg/close-icon.svg"
                class="close-icon"
                alt=""
                (click)="remove(notification.id)"
              />
            </div>
          </div>
        }
      }
    }
  }
</div>
