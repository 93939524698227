import { ProductDetailsResponse } from '@app/core/models/product/productDetailsResponse.model';
import { createAction, props } from '@ngrx/store';

export interface ICartActionProps {
  product: ProductDetailsResponse;
  productColorNumber: number;
}

export const addToCart = createAction('[Cart] Add to Cart', props<Partial<ICartActionProps>>());

export const removeFromCart = createAction('[Cart] Remove from Cart', props<Partial<ICartActionProps>>());

export const increaseSampleQuantity = createAction(
  '[Cart] Increase Sample Quantity',
  props<Partial<ICartActionProps>>()
);

export const decreaseSampleQuantity = createAction(
  '[Cart] Decrease Sample Quantity',
  props<Partial<ICartActionProps>>()
);

export const clearCart = createAction('[Cart] Clear Cart');
