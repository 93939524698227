import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { apiRoutes } from '@app/routes/constants/apiRoutes';
import { InterceptorConstants } from '@app/shared/constants/interceptorConstants';
import { environment } from '@environments/environment';
import { Observable, retry } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private credentials?: string;
  private tokenSecret: string = environment.authorizationBearerParams.client_secret;
  public requestWithHeaders!: HttpRequest<unknown>;

  constructor(@Inject(PLATFORM_ID) private platformId: object) {}

  addAuthToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    const contentType =
      req.detectContentTypeHeader() === InterceptorConstants.FORM_URL_ENCODED
        ? InterceptorConstants.FORM_URL_ENCODED
        : InterceptorConstants.JSON_CONTENT_TYPE;

    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType
      }
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { AUTHORIZATION_BEARER_TOKEN_API, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL, REGISTER_USER_URL } = apiRoutes;

    this.requestWithHeaders = request;

    const authRequest = request.url.includes(AUTHORIZATION_BEARER_TOKEN_API);
    const loginRequest = request.url.includes(`grant_type=${InterceptorConstants.COMMERCIAL_GRANT_TYPE}`);
    const forgotPasswordRequest = request.url.includes(FORGOT_PASSWORD_URL);
    const resetPasswordRequest = request.url.includes(RESET_PASSWORD_URL);
    const userRegistrationRequest = request.url.includes(REGISTER_USER_URL);

    // Skips all processes if this is in SSR
    if (isPlatformServer(this.platformId)) {
      return next.handle(this.requestWithHeaders);
    }

    this.credentials = btoa(
      `${environment.authorizationBearerParams.client_id}:${environment.authorizationBearerParams.client_secret}`
    );

    if (loginRequest) {
      this.requestWithHeaders = request.clone({
        setHeaders: {
          Authorization: `Basic ${this.credentials}`,
          'Content-Type': InterceptorConstants.LOGIN_CONTENT_TYPE
        }
      });
      return next.handle(this.requestWithHeaders);
    }

    if (authRequest) {
      this.requestWithHeaders = request.clone({
        params: request.params
          .append(InterceptorConstants.CLIENT_ID_KEY, environment.authorizationBearerParams.client_id)
          .append(InterceptorConstants.CLIENT_SECRET_KEY, this.tokenSecret)
          .append(InterceptorConstants.GRANT_TYPE_KEY, environment.authorizationBearerParams.grant_type)
      });
      return next.handle(this.requestWithHeaders);
    }

    if (forgotPasswordRequest || resetPasswordRequest || userRegistrationRequest) {
      const accessToken = localStorage.getItem('access_token') || '';
      return next.handle(this.addAuthToken(request, accessToken));
    }
    return next.handle(this.requestWithHeaders).pipe(retry(1));
  }
}
